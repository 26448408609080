/* eslint-disable import/no-relative-packages */
import React, {
  createContext, useContext, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import 'moment/min/locales.min';

import en from '../../resources/l10n/en.json';
import ba from '../../resources/l10n/ba.json';
import sr from '../../resources/l10n/sr.json';
import hr from '../../resources/l10n/hr.json';
import de from '../../resources/l10n/de.json';
import da from '../../resources/l10n/da.json';
import es from '../../resources/l10n/es.json';
import fr from '../../resources/l10n/fr.json';
import it from '../../resources/l10n/it.json';
import nl from '../../resources/l10n/nl.json';
import nn from '../../resources/l10n/nn.json';
import pl from '../../resources/l10n/pl.json';
import pt from '../../resources/l10n/pt.json';
import ro from '../../resources/l10n/ro.json';
import ru from '../../resources/l10n/ru.json';
import sk from '../../resources/l10n/sk.json';
import sl from '../../resources/l10n/sl.json';
import sq from '../../resources/l10n/sq.json';
import sv from '../../resources/l10n/sv.json';
import tr from '../../resources/l10n/tr.json';
import uk from '../../resources/l10n/uk.json';
import usePersistedState from '../util/usePersistedState';

const languages = {
  en: { data: en, name: 'English' },
  ba: { data: ba, name: 'Bosanski' },
  sr: { data: sr, name: 'Srpski' },
  hr: { data: hr, name: 'Hrvatski' },
  de: { data: de, name: 'Deutsch' },
  da: { data: da, name: 'Dansk' },
  es: { data: es, name: 'Español' },
  fr: { data: fr, name: 'Français' },
  it: { data: it, name: 'Italiano' },
  nl: { data: nl, name: 'Nederlands' },
  nn: { data: nn, name: 'Norsk nynorsk' },
  pl: { data: pl, name: 'Polski' },
  pt: { data: pt, name: 'Português' },
  ro: { data: ro, name: 'Română' },
  ru: { data: ru, name: 'Русский' },
  sk: { data: sk, name: 'Slovenčina' },
  sl: { data: sl, name: 'Slovenščina' },
  sq: { data: sq, name: 'Shqipëria' },
  sv: { data: sv, name: 'Svenska' },
  tr: { data: tr, name: 'Türkçe' },
  uk: { data: uk, name: 'Українська' },
};

const getDefaultLanguage = () => {
  const browserLanguages = window.navigator.languages ? window.navigator.languages.slice() : [];
  const browserLanguage = window.navigator.userLanguage || window.navigator.language;
  browserLanguages.push(browserLanguage);
  browserLanguages.push(browserLanguage.substring(0, 2));

  for (let i = 0; i < browserLanguages.length; i += 1) {
    let language = browserLanguages[i].replace('-', '');
    if (language in languages) {
      return language;
    }
    if (language.length > 2) {
      language = language.substring(0, 2);
      if (language in languages) {
        return language;
      }
    }
  }
  return 'en';
};

const LocalizationContext = createContext({
  languages,
  language: 'en',
  setLanguage: () => {},
});

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = usePersistedState('language', getDefaultLanguage());

  const value = useMemo(() => ({ languages, language, setLanguage }), [languages, language, setLanguage]);

  useEffect(() => {
    let selected;
    if (language.length > 2) {
      selected = `${language.slice(0, 2)}-${language.slice(-2).toLowerCase()}`;
    } else {
      selected = language;
    }
    moment.locale([selected, 'en']);
  }, [language]);

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);

export const useTranslation = () => {
  const context = useContext(LocalizationContext);
  const { data } = context.languages[context.language];
  return useMemo(() => (key) => data[key], [data]);
};

export const useTranslationKeys = (predicate) => {
  const context = useContext(LocalizationContext);
  const { data } = context.languages[context.language];
  return Object.keys(data).filter(predicate);
};
